<template>
  <Layout>
    <div class="container">
      <h1 class="is-clearfix">
        <span class="is-pulled-left">Companies</span>
        <button
          id="pdf"
          class="button is-info is-pulled-right"
          @click="active = 'is-active'"
        >
          Add a new Company
        </button>
      </h1>
      <section class="main-table-container">
        <table class="table is-striped is-fullwidth">
          <thead>
            <tr>
              <td>Name</td>
              <td>Website</td>
              <td>Address</td>
              <td v-if="isAdmin">Actions</td>
            </tr>
          </thead>
          <!-- END of thead -->
          <tbody>
            <tr v-for="company in companies" v-bind:key="company._id">
              <td>{{ company.name }}</td>
              <td>{{ company.webSite }}</td>
              <td>{{ company.address }}</td>
              <td v-if="isAdmin" class="actions">
                <div class="field has-addons">
                  <p class="control">
                    <a
                      class="button tooltip is-tooltip-bottom"
                      data-tooltip="Edit Company"
                      @click="promptUpdateCompany(company)"
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'pencil-alt']" />
                      </span>
                    </a>
                  </p>
                  <p class="control">
                    <a
                      class="button tooltip is-danger is-tooltip-bottom"
                      data-tooltip="Delete Company"
                      @click="confirmCompanyRemoval(company)"
                    >
                      <span class="icon">
                        <icon :icon="['fas', 'trash-alt']" />
                      </span>
                    </a>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
          <!-- END of tbody -->
        </table>
      </section>
      <div class="modal modalContainer" :class="active">
        <div class="modal-background"></div>
        <div class="modal-card modalBody">
          <header class="modal-card-head modalHeader">
            <p class="modal-card-title">
              <span v-if="!modalCompany._id">Add a New Company</span>
              <span v-else>Update {{ modalCompany.name }} company</span>
            </p>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Company Name</label>
                  <div class="control">
                    <input
                      v-model="modalCompany.name"
                      :class="{ 'is-danger': error.field === 'name' }"
                      class="input is-fullwidth"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Company WebSite</label>
                  <div class="control">
                    <input
                      v-model="modalCompany.webSite"
                      :class="{ 'is-danger': error.field === 'website' }"
                      class="input is-fullwidth"
                      type="text"
                    />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Company Address</label>
                  <div class="control">
                    <input
                      v-model="modalCompany.address"
                      :class="{ 'is-danger': error.field === 'address' }"
                      class="input is-fullwidth"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
            <p v-show="error.error" class="help is-danger">
              {{ error.message }}
            </p>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" @click="cancelModal()">
              Cancel
            </button>
            <button
              class="button is-success"
              @click="addCompany({ update: modalCompany._id ? true : false })"
            >
              <span v-if="!modalCompany._id">Add</span>
              <span v-else>Update</span>
            </button>
          </footer>
        </div>
      </div>
      <modal
        v-show="showModalConfirmation"
        @closeModal="closeModal"
        v-bind="modalConfig"
      />
    </div>
  </Layout>
</template>

<script>
import axios from 'axios';

export default {
  name: 'companies',
  components: {
    Layout: () => import('@/router/layouts/Main')
  },
  data() {
    return {
      companies: [],
      active: '',
      modalCompany: {
        name: '',
        webSite: '',
        address: ''
      },
      error: {
        error: false,
        message: 'Something went wrong'
      },
      companyToDelete: null,
      showModalConfirmation: false,
      modalConfig: {
        bodyText: 'Are you sure you want to delete this company?',
        titleText: 'Delete Confirmation',
        buttons: {
          confirm: {
            text: 'Delete',
            class: 'is-danger'
          },
          reject: {
            text: 'Cancel',
            class: ''
          }
        }
      }
    };
  },
  computed: {
    isAdmin() {
      return $cookies.get($formsConfig.core.cookieName).role === 'admin';
    }
  },
  methods: {
    //Add a new company
    async addCompany({ update }) {
      if (!this.modalCompany.name) {
        this.error.error = true;
        this.error.field = 'name';
        this.error.message = 'Company name is required.';
        return;
      } else if (!this.modalCompany.webSite) {
        this.error.error = true;
        this.error.field = 'website';
        this.error.message = 'Company website is required.';
        return;
      } else if (!this.modalCompany.address) {
        this.error.error = true;
        this.error.field = 'address';
        this.error.message = 'Company address is required.';
        return;
      }
      if (update) {
        this.updateCompany();
        return;
      }
      try {
        const response = await axios.post(
          $formsConfig.core.api.companies,
          this.modalCompany
        );
        this.error.error = false;
        this.companies.push(response.data);
        this.active = '';
        this.modalCompany.name = '';
        this.modalCompany.webSite = '';
        this.modalCompany.address = '';
      } catch (error) {
        if (error.response.status === 400) {
          this.error.error = true;
          this.error.field = 'name';
          this.error.message = error.response.data.message;
        }
      }
    },
    promptUpdateCompany(company) {
      this.modalCompany._id = company._id;
      this.modalCompany.name = company.name;
      this.modalCompany.webSite = company.webSite;
      this.modalCompany.address = company.address;
      this.active = 'is-active';
    },
    async updateCompany() {
      try {
        await axios.put(
          $formsConfig.core.api.companies + this.modalCompany._id,
          this.modalCompany
        );
        this.error.error = false;
        this.active = '';
        this.companies.forEach(company => {
          if (company._id === this.modalCompany._id) {
            company.name = this.modalCompany.name;
            company.webSite = this.modalCompany.webSite;
            company.address = this.modalCompany.address;
          }
        });
        this.modalCompany._id = null;
        this.modalCompany.name = '';
        this.modalCompany.webSite = '';
        this.modalCompany.address = '';
      } catch (error) {
        this.error.error = true;
        this.error.message = error.response.data.message;
      }
    },
    confirmCompanyRemoval(commpany) {
      this.showModalConfirmation = true;
      this.companyToDelete = commpany._id;
    },
    async closeModal(result) {
      if (result.remove) {
        await this.deleteCompany();
        this.showModalConfirmation = false;
      } else {
        this.showModalConfirmation = false;
      }
    },
    async deleteCompany() {
      let index;
      this.companies.forEach((el, i) => {
        if (el._id === this.companyToDelete) {
          return (index = i);
        }
      });
      try {
        const response = await axios.delete(
          $formsConfig.core.api.companies + this.companyToDelete
        );
        if (response.status === 200) {
          this.companies.splice(index, 1);
          this.companyToDelete = null;
        }
        return response;
      } catch (error) {
        console.error(error);
      }
    },
    cancelModal() {
      this.active = '';
      this.error.error = false;
      this.modalCompany.name = '';
      this.modalCompany.webSite = '';
      this.modalCompany.address = '';
    }
  },
  created() {
    // Fetch the list of forms for the companies.
    axios.get($formsConfig.core.api.companies).then(response => {
      this.companies = response.data;
    });
  }
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  > .columns {
    height: 100%;
    margin-top: 0;
    > .column {
      height: 100%;
    }
  }
}
.headers {
  font-size: 14px;
  font-weight: bold;
  color: $tittle-font;
}
</style>
